
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { NotFoundPage } from 'src/modules/not-found/page';
const NotFound = () => {
    return <NotFoundPage />;
};
NotFound.paddingBottom = 0;
export default NotFound;

    async function __Next_Translate__getStaticProps__18d6016c9ab__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18d6016c9ab__ as getStaticProps }
  