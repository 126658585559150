import { Container, Box, Stack, Typography, Button } from '@mui/material'
import { DeerIcon } from 'src/assets/icons'
import useTranslation from 'next-translate/useTranslation'
import { NextLink } from 'src/components/next-link'
import { pages } from 'src/app/pages'
import { EmptyResult } from 'src/components/empty-result'

const backgroundHeight = {
  xs: '548px',
  sm: '508px',
  lg: '376px',
  xl: '410px',
} as const

export const NotFoundPage = () => {
  const { t } = useTranslation()
  return (
    <Container sx={{ py: { xs: 8, sm: 11 } }} maxWidth="md">
      <EmptyResult backgroundHeight={backgroundHeight}>
        <Stack
          sx={{
            px: { xs: 3, lg: 15, xl: 22.5 },
            height: '100%',
          }}
          spacing={{ xs: 2, sm: 2.25, lg: 4 }}
          alignItems="center"
          justifyContent="center"
          direction={{ lg: 'row' }}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            color="primary2.main"
            spacing={{ xs: 0.75, lg: 1 }}
            sx={{
              width: { xs: 196, sm: 204, lg: 240 },
              height: { xs: 196, sm: 204, lg: 240 },
              background: 'rgba(204, 246, 232, 0.3)',
              borderRadius: '50%',
              flexShrink: 0,
            }}
          >
            <DeerIcon sx={{ fontSize: { xs: 64, lg: 88 } }} />
            <Box textAlign="center">
              {/* eslint-disable-next-line react/jsx-no-literals */}
              <Typography variant="h3ExtraBold">404</Typography>
              <Typography variant="bodySemiBold">
                {t('page_not_found')}
              </Typography>
            </Box>
          </Stack>
          <Stack
            spacing={{ xs: 3, lg: 4 }}
            alignItems={{ xs: 'center', lg: 'flex-start' }}
          >
            <Stack
              spacing={{ xs: 1.5, sm: 1, lg: 2 }}
              textAlign={{ xs: 'center', lg: 'left' }}
            >
              <Typography variant="h2ExtraBold" color="black">
                {t('are_you_lost')}
              </Typography>
              <Typography variant="bodySemiBold" color="secondary8.main">
                {t('page_not_found_description')}
              </Typography>
            </Stack>
            <NextLink href={pages['/']} underline="none">
              <Button variant="primary">{t('explore_fabnite')}</Button>
            </NextLink>
          </Stack>
        </Stack>
      </EmptyResult>
    </Container>
  )
}
