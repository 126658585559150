import { ReactNode } from 'react'
import { CurveLine, Dot } from 'src/assets/icons'
import { Box } from '@mui/material'
import { ResponsiveStyleValue } from '@mui/system'

type Props = {
  backgroundHeight: ResponsiveStyleValue<string>
  children: ReactNode
  redDotTopPosition?: ResponsiveStyleValue<number>
}

export const EmptyResult = ({
  backgroundHeight,
  children,
  redDotTopPosition,
}: Props) => {
  return (
    <Box
      sx={{
        position: 'relative',
        height: backgroundHeight,
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none' %3e%3crect width='100%25' height='100%25' fill='white' rx='12' ry='12' stroke='%23EAE9E9' stroke-width='2' stroke-dasharray='3 7' stroke-dashoffset='0' stroke-linecap='square' vector-effect='non-scaling-stroke' /%3e%3c/svg%3e")`,
        borderRadius: '12px',
      }}
    >
      <EmptyResultElements redDotTopPosition={redDotTopPosition} />
      {children}
    </Box>
  )
}

const EmptyResultElements = ({
  redDotTopPosition,
}: {
  redDotTopPosition?: ResponsiveStyleValue<number>
}) => {
  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          color: 'secondary3.main',
          top: { xs: 40 },
          left: { xs: 32, sm: 53, lg: 95, xl: 106 },
          lineHeight: 0,
        }}
      >
        <Dot sx={{ fontSize: { xs: 7, sm: 10, xl: 11 } }} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          color: 'secondary1.main',
          top: { xs: 77, sm: 74 },
          right: { xs: 40, sm: 66, lg: 119, xl: 132 },
          lineHeight: 0,
        }}
      >
        <Dot sx={{ fontSize: { xs: 6, sm: 9, lg: 10 } }} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          color: 'primary2.main',
          top: redDotTopPosition ?? { xs: 224, sm: 182 },
          right: { xs: 32, sm: 28, lg: 51, xl: 56 },
          lineHeight: 0,
        }}
      >
        <Dot sx={{ fontSize: { xs: 7, sm: 8, lg: 10 } }} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          color: 'primary3.main',
          bottom: 24,
          left: 32,
          lineHeight: 0,
        }}
      >
        <CurveLine sx={{ fontSize: { xl: 48 } }} />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          color: 'secondary2.main',
          top: 24,
          right: 32,
          lineHeight: 0,
        }}
      >
        <CurveLine sx={{ fontSize: { xl: 40 } }} />
      </Box>
    </>
  )
}
